import React from "react";
import { Table, Button, Col, Row, Input } from "reactstrap";
import { useTable, usePagination } from "react-table";
import { CSVLink } from "react-csv";

function TableStructure({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <>
      <Row style={{ overflowX: "scroll" }}>
        <Col md="12">
          <Table size="sm" bordered hover striped {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="mt-2">
            <Col md="1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </Col>
            <Col md="2">
              <Row>
                <Col md="6">
                  <span>Go to page:</span>
                </Col>
                <Col md="6">
                  <Input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="2">
              <Input
                type="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="1">
              <span>
                Page
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </Col>
            <Col md="1">
              <Button
                color="primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </Col>
            <Col md="3" />
            <Col md="2">
              <CSVLink
                data={data}
                filename={"Report.csv"}
                className="btn btn-success text-white"
                target="_blank"
              >
                <strong>Download Report</strong>
              </CSVLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

function ReportTable(props) {
  //Formats Data for display
  function FormatData(data) {
    let fields = [];
    data.forEach((field) => {
      //Phone number formatting
      let cleanPhone = field.PhoneNumber != null ? field.PhoneNumber : "-";
      cleanPhone = cleanPhone.charAt(0) === "+" ? cleanPhone : "As Is";
      let newField = {
        //agentId: field.AgentId,
        //initiationDate: field.InitiationDate != null ? field.InitiationDate : "-",
        //startTimeStamp: convertTimeStamp(field.StartTimestamp),
        date: field.Date != null ? field.Date.substring(0, 10) : "-",
        agentName: field.AgentName ? field.AgentName : "-",
        // initiationTime: field.InitiationTime != null ? field.InitiationTime : "-",
        // phoneNumber: field.PhoneNumber ? field.PhoneNumber : "-",
        conversationId:
          field.ConversationId != null ? field.ConversationId : "-",
        direction: field.Direction != null ? field.Direction : "-",
        sla: field.SLA != null ? field.SLA : "-",
        queueDuration: field.QueueDuration != null ? field.QueueDuration : "-",
        mediaType: field.MediaType ? field.MediaType : "-",
        queue: field.Queue != null ? field.Queue : "-",
        time: field.Time != null ? field.Time.substring(11, 19) : "-",
        // holdSeconds: field.HoldSeconds ? field.HoldSeconds : "-",
        holdDuration: field.HoldDuration ? field.HoldDuration : "-",
        holdCount: field.HoldCount ? field.HoldCount : "-",
        acw: field.ACW ? field.ACW : "-",
        callDuration: field.CallDuration ? field.CallDuration : "-",
      };
      fields.push(newField);
    });
    return fields;
  }

  const columns = React.useMemo(() => [
    {
      Header: "Conversation ID",
      accessor: "conversationId",
    },
    {
      Header: "Direction",
      accessor: "direction",
    },
    {
      Header: "SLA",
      accessor: "sla",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Answer Time",
      accessor: "time",
    },
    // {
    //   Header: "Initiation Time",
    //   accessor: "initiationTime",
    // },
    // {
    //   Header: "Phone Number",
    //   accessor: "phoneNumber",
    // },
    {
      Header: "Queue Duration",
      accessor: "queueDuration",
    },
    {
      Header: "Queue",
      accessor: "queue",
    },
    {
      Header: "Agent Name",
      accessor: "agentName",
    },
    {
      Header: "Media Type",
      accessor: "mediaType",
    },
    // {
    //   Header: "Hold Seconds",
    //   accessor: "holdSeconds",
    // },
    {
      Header: "Hold Duration",
      accessor: "holdDuration",
    },
    {
      Header: "Hold Count",
      accessor: "holdCount",
    },
    {
      Header: "ACW",
      accessor: "acw",
    },
    {
      Header: "Call Duration",
      accessor: "callDuration",
    },
    // {
    //   Header: "AgentId",
    //   accessor: "agentId",
    // },

    // {
    //   Header: "InitiationDate",
    //   accessor: "initiationDate",
    // },

    // {
    //   Header: "StartTimestamp",
    //   accessor: "startTimeStamp",
    // },
  ]);
  const data = FormatData(props.data);
  return <TableStructure columns={columns} data={data} />;
}

export default ReportTable;
