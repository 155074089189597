import React, { useEffect, useState } from "react";
import { Card, Table, Badge } from "reactstrap";
import { FaSort } from "react-icons/fa";
import ChangeAgentColor from "./ChangeAgentColor";
import convertTime from "../../AuxFunctions/convertTime";
import "./AgentStatusTable.css";

function AgentStatusTable(props) {
  const onCallStatus = "INTERACTING";
  const [sortedData, setSortedData] = useState([]);
  const [sortAttribute, setSortAttribute] = useState("");

  function toggleSort(attribute) {
    setSortAttribute(attribute);
    setSortedData([]);
    setSortedData(sortTable(attribute));
  }

  function sortTable(attribute) {
    let sorted_array;
    switch (attribute) {
      case "NAME":
        sorted_array = props.data.sort((a, b) =>
          a.AgentName > b.AgentName ? 1 : -1
        );
        break;
      case "STATUS":
        sorted_array = props.data.sort((a, b) =>
          a.AgentStatus > b.AgentStatus ? 1 : -1
        );
        break;
      case "DURATION":
        sorted_array = props.data.sort((a, b) =>
          a.StatusDuration > b.StatusDuration ? -1 : 1
        );
        break;
      case "AHT":
        sorted_array = props.data.sort((a, b) => (a.AHT > b.AHT ? -1 : 1));
        break;
      case "CALLS":
        sorted_array = props.data.sort((a, b) =>
          a.CallsTotal > b.CallsTotal ? -1 : 1
        );
      case "RONA":
        sorted_array = props.data.sort((a, b) => (a.RONA > b.RONA ? -1 : 1));
      default:
        sorted_array = props.data;
        break;
    }
    return sorted_array;
  }

  useEffect(() => {
    setSortedData(sortTable(sortAttribute));
  });

  return (
    <Card className="table-card">
      <Table size="sm" responsive hover striped className="agents-table">
        <thead>
          <tr>
            <th>
              Agent{" "}
              <i
                onClick={() => {
                  toggleSort("NAME");
                }}
              ><FaSort /></i>
            </th>
            <th>
              Status{" "}
              <i
                onClick={() => {
                  toggleSort("STATUS");
                }}
              ><FaSort /></i>
            </th>
            <th>
              Duration{" "}
              <i
                onClick={() => {
                  toggleSort("DURATION");
                }}
              ><FaSort /></i>
            </th>
            <th>
              AHT{" "}
              <i
                onClick={() => {
                  toggleSort("AHT");
                }}
              ><FaSort /></i>
            </th>
            <th>
              Calls{" "}
              <i
                onClick={() => {
                  toggleSort("CALLS");
                }}
              ><FaSort /></i>
            </th>
            <th>
              RONA{" "}
              <i
                onClick={() => {
                  toggleSort("RONA");
                }}
              ><FaSort /></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((agent, index) =>
            agent.AgentPresence !== "Offline" ? (
              <tr key={index}>
                <td>{agent.AgentName}</td>
                <td>
                  {/* color#000000 invalidates default bootstrap colors so we can input custom ones*/}
                  <Badge color={"#000000"} style={{backgroundColor: ChangeAgentColor(agent.AgentStatus)}}>
                    {agent.AgentStatus === "INTERACTING"
                      ? "INTER"
                      : agent.AgentStatus}
                  </Badge>
                </td>
                <td
                  className={
                    agent.AgentStatus === onCallStatus &&
                    agent.StatusDuration > 720
                      ? "text-danger"
                      : null
                  }
                >
                  {agent.AgentStatus === onCallStatus &&
                  agent.StatusDuration > 720 ? (
                    <>
                      <Badge color="danger">
                        {convertTime(agent.StatusDuration)}{" "}
                        <i className="fas fa-stopwatch" />
                      </Badge>{" "}
                    </>
                  ) : (
                    convertTime(agent.StatusDuration)
                  )}
                </td>
                <td>
                  {agent.AHT === "" ? "-" : convertTime(agent.AHT / 1000)}
                </td>
                <td>{agent.CallsTotal === "" ? "-" : agent.CallsTotal}</td>
                <td>{agent.RONA === "" ? "-" : agent.RONA}</td>
              </tr>
            ) : null
          )}
        </tbody>
      </Table>
    </Card>
  );
}

export default AgentStatusTable;
