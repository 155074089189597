// ---- MAIN CONFIGURATION ---- //
// Please set the environment:
const environment = "prod"; // dev, prod, or local

// Please set your cognito domain:
const CognitoDomain = "manulife-wallboard.auth.us-east-2.amazoncognito.com";

// Please set your Cognito user pool ID:
const userPoolId = "us-east-2_uhhv7GvIC";

const redirectUrl = "https://manulife-wallboard.tcsi2i.com"

const api_endpoint = "https://vxj5ntfmal.execute-api.us-east-2.amazonaws.com/"
        
const stage = "PROD/";

const api_url = api_endpoint+stage;

const userPoolWebClientId = "7f9vh06oc9sduamir90hvkn8e";

const cookieDomain = ".manulife-wallboard.tcsi2i.com";


export const AmplifyConfig = {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-east-2",
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: userPoolId,
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: userPoolWebClientId, 
  
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is st to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: cookieDomain, 
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: "lax", //"strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },
      // OPTIONAL - Hosted UI configuration
      oauth: {
        // BASE DOMAIN
        domain: CognitoDomain, //No incluir "https://" pues la librería lo incluye por sí misma.
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        // --- REDIRECT URLS ---
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
        responseType: "code", // code or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
        endpoints: [
            {
                name: "wallboard",
                endpoint: api_url
            } 
        ],
    }
};

export { environment, api_url };