export default function convertTime(secs) {
    if (secs === 0 || isNaN(secs)) {
      return "0:00";
    }
    let time, hours, minutes, seconds;
    time = secs;
    hours = Math.floor(time / 3600);
    
    if (hours > 0){
      time = time - hours * 3600;
    }

    minutes = Math.floor(time / 60);
    seconds = time - minutes * 60;
    
    // Return second decimals
    if (minutes === 0 && seconds < 1 && seconds > 0){
      return seconds.toFixed(2) + "s";
    }
    seconds = Math.floor(seconds);

    if (seconds > 59){
      minutes ++;
      seconds = 0;
    }
    if (hours === 1) {
      return hours + " hour " + minutes + " min."
    }
    else if (hours > 1) {
      return hours + " hours " + minutes + " min."
    }
    else if (minutes === 0 && seconds > 1) {
      return seconds.toFixed(0) + "s";
    } 
    else if (minutes >= 1){
      return minutes + "m " + seconds.toFixed(0) + "s";
    }
    else {
      return minutes +"m " + seconds.toFixed(0) + "s";
    }
  }