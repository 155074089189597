import { Row, Col } from "reactstrap";
import ReportingArea from "../../components/ReportingArea/ReportingArea";
import "./ReportView.css";

function ReportView() {
  return (
    <>
      <Row className="pt-2">
        <Col>
          <ReportingArea></ReportingArea>
        </Col>
      </Row>
    </>
  );
}
export default ReportView;
