import "./DataCard.css";
import { useState } from "react";
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledCollapse,
  Row,
  Col,
  Button,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { colors } from "./CardColors";
import { month } from "../../variables/months";
import convertTime from "../../AuxFunctions/convertTime";
import humanNumber from "../../AuxFunctions/humanNumber";


function getColor(color) {
  return colors[color];
}

function DataCard(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function collapseHandler() {
    console.log("Is collapsed ?: " + isCollapsed);
    setIsCollapsed(!isCollapsed ? true : false);
  }

  let color = getColor(props.color);
  let eng_day, eng_mon, fr_day, fr_mon;

  try {
    if (
      props.metric === "averageHandle" ||
      props.metric === "ASA" ||
      props.metric === "averageTalk"
    ) {
      eng_day = convertTime(props.english.day[props.metric] / 1000);
      eng_mon = convertTime(props.english.month[props.metric] / 1000);
      fr_day = convertTime(props.french.day[props.metric] / 1000);
      fr_mon = convertTime(props.french.month[props.metric] / 1000);
    } else {
      eng_day = humanNumber(props.english.day[props.metric]);
      eng_mon = humanNumber(props.english.month[props.metric]);
      fr_day = humanNumber(props.french.day[props.metric]);
      fr_mon = humanNumber(props.french.month[props.metric]);
    }
  } catch {
    console.error("An error ocurred on DataCard component.");
  }

  return (
    <Card className="card">
      <CardHeader
        className="cardHeader"
        style={{ backgroundColor: color.dark }}
      >
        <CardTitle>
          <Row>
            <Col className="text-center" md="3">
              {props.icon}
            </Col>
            <Col md="6" className="text-center">
              {props.title}
            </Col>
            <Col md="3">
              <Button
                className="hide-btn"
                size="sm"
                id={"toggler-" + props.id}
                onClick={collapseHandler}
              >
                  {isCollapsed ? <FaEye/> : <FaEyeSlash/>}
              </Button>
            </Col>
          </Row>
        </CardTitle>
      </CardHeader>
      <UncontrolledCollapse defaultOpen toggler={"#toggler-" + props.id}>
        <CardBody>
          <Table borderless size="sm" className="card-table">
            <thead>
              <tr>
                {
                  //Conditional rendering for Abandoned card percentages
                  props.metric === "abandoned" ? (
                    <>
                      <th></th>
                      <th className={props.textColor}>English</th>
                      <th className={props.textColor}>French</th>
                    </>
                  ) : (
                    <>
                      <th></th>
                      <th className={props.textColor}>English</th>
                      <th className={props.textColor}>French</th>
                    </>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                //Conditional rendering for Abandoned card percentages
                props.metric === "abandoned" ? (
                  <>
                    <tr>
                      <th className={props.textColor}>Today</th>
                      <td>
                        {props.english.length > 0 || props.english != null
                          ? eng_day + "\n(" + props.english.day.abandonedp + ")"
                          : "-"}
                      </td>
                      <td>
                        {props.french.length > 0 || props.french != null
                          ? fr_day + "\n(" + props.french.day.abandonedp + ")"
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th className={props.textColor}>{month}</th>
                      <td>
                        {props.english.length > 0 || props.english != null
                          ? eng_mon +
                            "\n(" +
                            props.english.month.abandonedp +
                            ")"
                          : "-"}
                      </td>
                      <td>
                        {props.french.length > 0 || props.french != null
                          ? fr_mon + "\n(" + props.french.month.abandonedp + ")"
                          : "-"}
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <th className={props.textColor}>Today</th>
                      <td>{eng_day}</td>
                      <td>{fr_day}</td>
                    </tr>
                    <tr>
                      <th className={props.textColor}>{month}</th>
                      <td>{eng_mon}</td>
                      <td>{fr_mon}</td>
                    </tr>
                  </>
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
}

export default DataCard;
