import "./Footer.css";
import { FaHeart } from "react-icons/fa";

function Footer(props) {
  return (
    <footer className="App-footer">
      <p style={{textAlign: "center"}}>Made with <FaHeart /> by i2i</p>
    </footer>
  );
}

export default Footer;
