export default function reduceAgentStatus(agents_arr){
    if (!Array.isArray(agents_arr)){
        return {}
    }
    
    let output = {};
    agents_arr.forEach(agent => {
        if(!output[agent.AgentStatus]){
            output[agent.AgentStatus] = 0;
        }
        output[agent.AgentStatus] += 1;
    });

    return output;
}