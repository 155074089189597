const colors = {
  red : {
    dark : "#BD0000",
    light : "#FF4E4E",
  },
  blue : {
    dark : "#4973E7",
    light : "#4184F9",
  },
  green : {
    dark : "#166e24",
    light :  "#39DB9D",
  },
  yellow : {
    dark : "#daa804",
    light : "#fbcc32",
  },
  purple : {
    dark : "#913DE0",
    light : "#b780ea",
  },
  turquoise : {
    dark : "#20aca8",
    light : "#a3efed",
  },
  orange : {
    dark : "#f74c08",
    light : "#f97743",
  },
  pink : {
    dark : "#f93ea5",
    light : "#fb8dca",
  },
}

export { colors };