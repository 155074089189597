const chartColors = {
    default: {
        dark: "#375ebf",
        light: "#4775eb",
    },
    Meal: {
        dark: "#5a349e",
        light: "#6f42c1",
    },
    Break: {
        dark: "#5a349e",
        light: "#6f42c1",
    },
    Busy: {
        dark: "#4f565c",
        light: "#6c757d",
    },
    Available: {
        dark: "#c97c18",
        light: "#eb911c",
    },
    IDLE: {
        dark: "#268c36",
        light: "#30aa43",
    },
    Training: {
        dark: "#a32f2f",
        light: "#d84343",
    },
    Away: {
        dark: "#4f565c",
        light: "#6c757d",
    },
    Meeting: {
        dark: "#a32f2f",
        light: "#d84343",
    },
    INTERACTING: {
        dark: "#375ebf",
        light: "#4775eb",
    },
    NOT_RESPONDING: {
        dark: "#a32f2f",
        light: "#d84343",
    }
}

export { chartColors };