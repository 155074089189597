// Amplify
import Amplify from "aws-amplify";
import { AmplifyConfig } from "./variables/AmplifyConfig";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import React from "react";
import AuthLayer from "./Layers/AuthLayer/AuthLayer";

Amplify.configure(AmplifyConfig);

function App() {
  return (
      <AuthLayer/>
  );
}

export default App;
