import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardBody, CardTitle } from "reactstrap";
import { api_url } from "../../variables/AmplifyConfig";
import ReportForm from "../ReportForm/ReportForm";
import ReportTable from "../ReportTable/ReportTable";
import FetchingData from "../FetchingData/FetchingData";
import getCognitoToken from "../../AuxFunctions/getCognitoToken";
import "./ReportingArea.css";

function ReportingArea() {
  const [reportData, setReportData] = useState([]);
  const [fetching, setFetching] = useState(false);

  async function FetchTableData(startDate, endDate) {
    setFetching(true);
    let cognitoToken = await getCognitoToken();
    let lastEvaluatedRange;
    let call = [];
    const path = "report";

    let requestOptions = {
      method: "POST",
      headers: {
        Authorization: cognitoToken.headers.Authorization,
      },
      body: JSON.stringify({
        range: {
          start: startDate,
          end: endDate,
        },
      }),
      redirect: "follow",
    };

    do {
      let request = await fetch(`${api_url}${path}`, requestOptions);
      let response = await request.json();
      if (!response.error) {
        setFetching(false);
        try {
          lastEvaluatedRange = response.lastEvaluatedRange;
          call = call.concat(response.data.Items);
        } catch {
          console.error("Error while fetching stats from API");
        }
      }
      requestOptions.body = JSON.stringify({
        range: lastEvaluatedRange,
      });
    } while (lastEvaluatedRange);
    setReportData(call);
  }

  useEffect(() => {}, []);

  return fetching ? (
    <FetchingData/>
  ) : (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <CardTitle className="pb-4">
                <ReportForm FetchData={FetchTableData}></ReportForm>
              </CardTitle>
              {reportData.length < 1 ? null : (
                <ReportTable data={reportData}></ReportTable>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col>
          {reportData.length < 1 ? (
            <h1 className="text-center py-3"> Nothing to show 🤔</h1>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default ReportingArea;
