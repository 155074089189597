import "./AuthLayer.css";
import { Authenticator } from "@aws-amplify/ui-react";
import MainLayer from "../MainLayer/MainLayer";
import logo from "../../components/Header/manulife-logo.png";

const components = {
  Header() {
    return (
      <h1 className="login-header">
        {/* <img src={logo} alt="logo" className="logo-style" /> */}
        &nbsp;Wallboard
      </h1>
    );
  },
};

function AuthLayer() {
  return (
    <div className="auth-wrapper">
      <Authenticator
        className="Authenticator"
        loginMechanisms={["email"]}
        initialState="signIn"
        components={components}
      >
        {({ signOut, user }) => <MainLayer user={user} signOut={signOut} />}
      </Authenticator>
    </div>
  );
}

export default AuthLayer;
