import { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledCollapse,
  Row,
  Button,
  Col,
} from "reactstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import reduceAgentStatus from "./reduceAgentStatus";
import { chartColors } from "./ChartColors";
import "./AgentStatusChart.css";
import FetchingData from "../FetchingData/FetchingData";
import { FaChartPie, FaEye, FaEyeSlash } from "react-icons/fa";

ChartJS.register(ArcElement, Tooltip, Legend);

function AgentStatusChart(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function collapseHandler() {
    console.log("Is collapsed ?: " + isCollapsed);
    setIsCollapsed(!isCollapsed ? true : false);
  }

  const agentsStatus = reduceAgentStatus(props.agentsContext);
  const agentCount = Object.keys(props.agentsContext).length;
  const options = {
    plugins: {
      legend: {
        position: "left",
      },
    },
  };

  const data = {
    labels: Object.keys(agentsStatus).map((key) => key),
    datasets: [
      {
        label: "# of Votes",
        data: Object.keys(agentsStatus).map((key) => agentsStatus[key]),
        backgroundColor: Object.keys(agentsStatus).map((key) =>
          chartColors[key] != null
            ? chartColors[key].light
            : chartColors[0].light
        ),
        borderColor: Object.keys(agentsStatus).map((key) =>
          chartColors[key] != null ? chartColors[key].dark : chartColors[0].dark
        ),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card className="agents-chart-card">
      <CardHeader className="bg-primary">
        <Row>
          <Col md="3" className="text-center">
            <FaChartPie/>
          </Col>
          <Col md="6" className="text-center">
            Online: {agentCount}
          </Col>
          <Col md="3" className="text-center">
            <Button
              id="chart"
              style={{ backgroundColor: "transparent", border: "transparent" }}
              size="sm"
              onClick={collapseHandler}
            >
              {isCollapsed ? <FaEye/>: <FaEyeSlash/>}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <UncontrolledCollapse defaultOpen toggler="#chart">
        <CardBody>
          {Object.keys(agentsStatus).length > 0 ? (
            <Pie data={data} options={options} />
          ) : (
            <FetchingData noText={true} />
          )}
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
}

export default AgentStatusChart;
