import { useState } from "react";
import "./MainView.css";
import { Row, Col, Button, Collapse } from "reactstrap";
import { FaChartBar } from "react-icons/fa";
import AgentsStatusArea from "../../components/AgentsStatusArea/AgentsStatusArea";
import MetricsArea from "../../components/MetricsArea/MetricsArea";

function MainView(props) {
  const [showMetrics, toggleMetrics] = useState(true);
  const [agentsContext, setAgentsContext] = useState([]);

  return (
    <>
        <div className="main">
          <Button
            onClick={() => {
              toggleMetrics(!showMetrics);
              document.activeElement.blur();
            }}
            className="metrics-toggle btn-lg"
          >
            <FaChartBar />
          </Button>

          <Row>
            <Col lg={showMetrics ? "10" : "12"} md={showMetrics ? "10" : "12"}>
              <AgentsStatusArea setAgentsContext={setAgentsContext} />
            </Col>

            <Col lg="2" md="2" sm="12">
              <Collapse isOpen={showMetrics}>
                <MetricsArea agentsContext={agentsContext} />
              </Collapse>
            </Col>
          </Row>
        </div>
    </>
  );
}
export default MainView;
