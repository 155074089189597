import React, { Component } from "react";
import { CardBody } from "reactstrap";
import { FaEllipsisH } from "react-icons/fa";

import "./FetchingData.css";

class FetchingData extends Component {
  render() {
    return (
      <CardBody className="fetching-data text-center">
        {this.props.noText ? null : <h5>Fetching data</h5>}
        <FaEllipsisH/>
      </CardBody>
    );
  }
}

export default FetchingData;
