import { useState } from "react";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import "./ReportForm.css";

function ReportForm(props) {
  const [modal, setModal] = useState(false);
  //const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //const [startDate, endDate] = dateRange;

  function modalToggle() {
    setModal(!modal);
  }



  function fetchReport() {
    if (startDate && endDate) {
      let startNY = moment.tz(startDate, "America/New_York");
      let endNY = moment.tz(endDate, "America/New_York");
      props.FetchData(startNY.format(), endNY.format());
      console.log(startNY.format());
      console.log(endNY.format());
    } else {
      modalToggle();
    }
  }

  return (
    <>
      <Modal isOpen={modal} size={"sm"} toggle={modalToggle}>
        <ModalBody className="modal-content">
          <p>Please select valid dates!</p>
          <i class="fa fa-frown-o fa-lg" aria-hidden="true"></i>
        </ModalBody>
      </Modal>
      <Row>
        <Col md="12" className="text-center">
          <p>Please select a start and end date to create a report: </p>
        </Col>
      </Row>
      <Row>
        <Col md="3"></Col>
        <Col md="2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            placeholderText="Start Date"
            showTimeInput
            withPortal
          />
        </Col>
        <Col md="2">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            placeholderText="End Date"
            showTimeInput
            withPortal
          />
        </Col>
        <Col md="2">
          <Button color="success" size="sm" block onClick={fetchReport}>
            <strong>Submit</strong>
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ReportForm;
