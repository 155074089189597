function SplitArray(array)
{
  if(!Array.isArray(array))
  {
    let emptyArr =  [[],[]]
    return emptyArr;
  }

  let half = Math.ceil(array.length /2); 

  return [array.slice(0, half), array.slice(half, array.length+1)];
}

export default SplitArray;