import { FaPhoneSlash, FaHandshake, FaUserClock, FaArrowCircleDown, FaPhone, 
FaStopwatch, FaArrowAltCircleUp } from "react-icons/fa";

let metrics = {
  abandoned: {
    id: "abandoned",
    title: "Abandoned",
    color: "red",
    textColor : "text-danger",
    icon: <FaPhoneSlash/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  serviceLevel: {
    id: "SL",
    title: "Service Level",
    color: "red",
    textColor : "text-success",
    icon: <FaHandshake/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  ASA: {
    id: "ASA",
    title: "ASA",
    color: "red",
    textColor : "text-success",
    icon: <FaUserClock/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  offered: {
    id: "offered",
    title: "Offered",
    color: "blue",
    textColor : "text-blue",
    icon: <FaArrowCircleDown/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  answered: {
    id: "answered",
    title: "Answered",
    color: "green",
    textColor : "text-success",
    icon: <FaPhone/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  averageHandle: {
    id: "averageHandle",
    title: "AHT",
    color: "yellow",
    textColor : "text-purple",
    icon: <FaStopwatch/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  averageTalk: {
    id: "averageTalk",
    title: "ATT",
    color: "yellow",
    textColor : "text-purple",
    icon: <FaStopwatch/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
  callback: {
    id: "callback",
    title: "Callback",
    color: "purple",
    textColor : "text-turquoise",
    icon: <FaArrowAltCircleUp/>,
    english: {
      day: {},
      month: {},
    },
    french: {
      day: {},
      month: {},
    },
  },
};

export { metrics };
