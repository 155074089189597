import {Auth} from "aws-amplify";

async function getCognitoToken(){
    let init = {
        headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }
    }
    return init
}


export default getCognitoToken;