function ChangeAgentColor(status) {
  let agentColor;
  switch (status) {
    case "On Queue":
      //agentColor = "info";
      agentColor = "#0dcaf0";
      break;
    case "Available":
      //agentColor = "warning";
      agentColor = "#eb911c";
      break;
    case "IDLE":
      //agentColor = "success";
      agentColor = "#30aa43";
      break;
    case "Meal":
    case "Break":
      //agentColor = "purple";
      agentColor = "#6f42c1";
      break;
    case "Meeting":
    case "Training":
    case "NOT_RESPONDING":
      //Red
      agentColor = "#d84343";
      break;
    case "Busy":
    case "Away":
      //agentColor = "secondary";
      agentColor = "#6c757d";
      break;
    case "INTERACTING":
    default:
      //agentColor = "primary";
      agentColor = "#4775eb";
  }
  return agentColor;
}

export default ChangeAgentColor;
