import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import AgentStatusTable from "../../components/AgentStatusTable/AgentStatusTable";
// Functions
import getCognitoToken from "../../AuxFunctions/getCognitoToken";
import SplitArray from "../../AuxFunctions/splitArray";
// Variables
import { api_url } from "../../variables/AmplifyConfig";
import FetchingData from "../FetchingData/FetchingData";
import "./AgentsStatusArea.css";

function AgentsStatusArea(props) {
  const [agentData, setAgentData] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    FetchAgentsStatus();
    let interval = setInterval(() => {
      FetchAgentsStatus();
    }, 3000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  async function FetchAgentsStatus() {
    const path = "agents-status";
    let options = await getCognitoToken();
    fetch(`${api_url}${path}`, options)
      .then((response) => response.json())
      .then((response) => {

        if (!response.error) {
          let cleanData = [];
          try {
            response.data.map((agent) =>
              agent.AgentStatus !== "Offline" ? cleanData.push(agent) : null
            );
            setAgentData(cleanData);
            props.setAgentsContext(cleanData); //This will pass context to parent component
          }
          catch {
            console.error("Error while processing agents from api response.");
          }
          setFetching(false);
        }

      });

  }

  let agents;
  if (agentData.length > 19) {
    agents = SplitArray(agentData);
  }

  return fetching ? (
    <FetchingData noText={true} />
  ) : (
    <>
      {
        agentData.length > 19 ? (
          <Row>
            <Col md="6">
              <AgentStatusTable data={agents[0]} />
            </Col>
            <Col md="6">
              <AgentStatusTable data={agents[1]} />
            </Col>
          </Row>
        ) : (
          <AgentStatusTable data={agentData} />
        )}

    </>
  );
}

export default AgentsStatusArea;
