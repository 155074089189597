import "./Header.css";
import { api_url } from "../../variables/AmplifyConfig";
import { Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import getCognitoToken from "../../AuxFunctions/getCognitoToken";
import logo from "./manulife-logo.png";

function queueColor(queue) {
  let color;
  if (queue > 3) {
    color = "text-danger";
  } else if (queue >= 1) {
    color = "text-warning";
  } else {
    color = "text-default";
  }
  return color;
}

function Header(props) {
  const [queue, setQueue] = useState({ queueEnglish: 0, queueFrench: 0 });
  async function fetchCallsInQueue() {
    let path = "calls-in-queue";
    const options = await getCognitoToken();
    fetch(`${api_url}${path}`, options)
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) setQueue(response);
      });
  }

  let queueEng, queueFr;
  try {
    queueEng = queue.queueEnglish;
    queueFr = queue.queueFrench;
  } catch {
    console.error("Error on queues");
  }

  let navigate = useNavigate();
  function logOutHandler() {
    props.signOut();
    navigate("../", { replace: true });
  }

  useEffect(() => {
    fetchCallsInQueue();
    setInterval(() => {
      fetchCallsInQueue();
    }, 3000);
  }, []);

  return (
    <header className="App-header">
      <Row>
        <Col style={{ display: "flex" }}>
          {/* <img
            style={{ paddingLeft: 4 + "%" }}
            src={logo}
            alt="logo"
            className="logo-style"
          /> */}
          <h3 style={{ paddingLeft: 35 + "%", marginTop: 1.5 + "%" }}>Wallboard</h3>
        </Col>
        <Col className="queues">
          <i className="fas fa-phone text-info" />
          &nbsp; Calls in queue -&nbsp; English:&nbsp;
          <span className={queueColor(queueEng)}>{queueEng}</span>&nbsp;
          French:&nbsp;
          <span className={queueColor(queueFr)}>{queueFr}</span>
        </Col>
        <Col>
          <p
            className="username text-primary"
            style={{ marginTop: 2 + "%", marginRight: 6 + "px" }}
          >
            <Link to="/" style={{ textDecoration: "none", color: 'white' }}>
              Wallboard
            </Link>
            &nbsp;&nbsp;
            <Link to="/reporting_portal" style={{ textDecoration: "none", color: 'white' }}>
              Reporting
            </Link>
            <span onClick={logOutHandler} className="text-warning sign-out">
              Sign out&nbsp;&nbsp;
              <i className="fas fa-sign-out-alt" />
            </span>
          </p>
        </Col>
      </Row>
    </header>
  );
}

export default Header;
