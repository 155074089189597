import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Wallboard from "../../views/MainView/MainView";
import ReportingPortal from "../../views/ReportView/ReportView";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Container} from "reactstrap"

function MainLayer(props) {
  return (
    <>
      <Container fluid className="">
        <Router>
          <Header user={props.user} signOut={props.signOut} />
          <Routes>
            <Route path="/" element={<Wallboard user={props.user} signOut={props.singOut} />} />
            <Route path="/reporting_portal" element={<ReportingPortal user={props.user} signOut={props.singOut} />} />
          </Routes>
          <Footer signOut={props.signOut} />
        </Router>
      </Container>
    </>
  );
}

export default MainLayer;
