import { useEffect, useState } from "react";
import { metrics } from "./MetricsConfig";
import { api_url } from "../../variables/AmplifyConfig";
import DataCard from "../DataCards/DataCard";
import getCognitoToken from "../../AuxFunctions/getCognitoToken";
import AgentStatusChart from "../AgentStatusChart/AgentStatusChart";
import FetchingData from "../FetchingData/FetchingData";
import "./MetricsArea.css";

function MetricsArea(props) {
  const [fetching, setFetching] = useState(true);
  const [stats, setStats] = useState({ eng: {}, fr: {} });

  async function fetchStats() {
    const path = "stats";
    let options = await getCognitoToken();
    fetch(`${api_url}${path}`, options)
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          try {
            setStats(response.data);
            setFetching(false);
          } catch {
            console.error("Error while fetching stats from API");
          }
        }
      });
  }

  useEffect(() => {
    fetchStats();
    let interval = setInterval(() => {
      fetchStats();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return fetching ? (
    <FetchingData noText={true} />
  ) : (
    <div className="metrics-area">
      {Object.keys(metrics).map((key, index) => (
        <DataCard
          key={index}
          metric={key}
          title={metrics[key].title}
          english={stats.eng}
          french={stats.fre}
          icon={metrics[key].icon}
          color={metrics[key].color}
          textColor={metrics[key].textColor}
          id={metrics[key].id}
        />
      ))}
      <AgentStatusChart agentsContext={props.agentsContext} />
    </div>
  );
}

export default MetricsArea;
